import React from 'react';
import { Link } from 'react-router-dom';

// components
import { Text } from 'components';

// styles
import styles from './Logo.module.css';

function Logo({ mb }: { mb?: number }) {
    return (
        <Link style={{ marginBottom: `${mb}px` }} to="home" className={styles.container}>
            <div className={styles.logoIcon} />
            <Text text="Actitech Limited" type="subtitle" uppercase />
        </Link>
    );
}

export default Logo;
