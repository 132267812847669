import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Slider.module.css';

// constants
import { SLIDER_LIST } from 'constants/projects';

// types
import { ProjectType } from 'types/types';

const SLIDE_GAP_WIDTH = 16;
const SLIDE_WIDTH = 207 + SLIDE_GAP_WIDTH;
const IS_TABLET = window.innerWidth <= 768;

const cx = classNames.bind(styles);

function Slider({ currentProject }: { currentProject: ProjectType }) {
    const [leftOffset, setLeftOffset] = useState(0);
    const [isSliderInit, setIsSliderInit] = useState(false);

    const sliderWrapperRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (sliderWrapperRef.current) {
            sliderWrapperRef.current.style.transform = `translate(${-leftOffset}px, 0)`;
        }

        if (leftOffset !== 0) {
            setIsSliderInit(true);
        } else {
            Boolean(isSliderInit) && setIsSliderInit(false);
        }
    }, [leftOffset]);

    const sliderRailsClasses = cx({
        sliderRails: true,
        isSliderInit,
    });

    const increaseOffset = () => {
        if (leftOffset !== 0) {
            setLeftOffset(leftOffset - SLIDE_WIDTH);
        }
    };

    const decreaseOffset = () => {
        const lastItemIndex = SLIDER_LIST[currentProject].length - 1;
        const sliderWidth = SLIDE_WIDTH * lastItemIndex;

        if (leftOffset < sliderWidth) {
            setLeftOffset(leftOffset + SLIDE_WIDTH);
        }
    };

    return (
        <div className={styles.sliderWindow}>
            <div className={sliderRailsClasses}>
                <div className={styles.slider} ref={sliderWrapperRef}>
                    {SLIDER_LIST[currentProject].map((item, index) => (
                        <img
                            key={index}
                            src={item}
                            className={styles.sliderItem}
                            alt={`slide${currentProject}`}
                        />
                    ))}
                </div>
                {IS_TABLET && (
                    <div className={styles.sliderControls}>
                        <div className={styles.left} onClick={increaseOffset} />
                        <div className={styles.right} onClick={decreaseOffset} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Slider;
