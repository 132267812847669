import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';

// styles
import styles from './Text.module.css';

// types
import { TextComponentPropsType } from './types';

const cx = classNames.bind(styles);

const Text = ({ text, children, type, bold, uppercase, mb, color }: TextComponentPropsType) => {
    const textRef = useRef<HTMLParagraphElement | null>(null);

    useEffect(() => {
        if (textRef.current && color) {
            textRef.current.style.color = `var(--${color})`;
        }
    }, [color]);

    useEffect(() => {
        if (textRef.current && mb) {
            textRef.current.style.marginBottom = `${mb}px`;
        }
    }, [mb]);

    const textClasses = cx({
        defaultText: true,
        uppercase,
        bold,
        ...(type && { [type]: type }),
    });

    return (
        <p ref={textRef} className={textClasses}>
            {text || children}
        </p>
    );
};

export default Text;
