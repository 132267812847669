import React from 'react';
import { useNavigate } from 'react-router-dom';

// constants
import { PROJECT_ICONS, PROJECT_LIST, PROJECT_NAMES, PROJECT_TITLES } from 'constants/projects';

// components
import { FeedBackForm, Footer, ProductCard, Text } from 'components';

// styles
import styles from './Home.module.css';

// img
import person1 from './img/person1.webp';
import person2 from './img/person2.webp';

function Home() {
    const navigate = useNavigate();

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.container}>
                <div className={styles.contentWrapper}>
                    <div>
                        <Text
                            text="Improving the Health of Millions of People around the World"
                            type="h1"
                            mb={24}
                        />
                        <div className={styles.mediaWrapper}>
                            <img src={person1} alt="person1" />
                            <img src={person2} alt="person2" />
                        </div>
                    </div>
                    <div className={styles.productsList}>
                        <Text uppercase text="Explore our products" type="subtitle" />
                        {PROJECT_LIST.map((product, index) => (
                            <ProductCard
                                icon={PROJECT_ICONS[product]}
                                key={index}
                                onClick={() => navigate(`products/${index}`)}
                                title={PROJECT_NAMES[product]}
                                subtitle={PROJECT_TITLES[product]}
                            />
                        ))}
                    </div>
                </div>
                <FeedBackForm home />
            </div>
            <Footer />
        </div>
    );
}

export default Home;
