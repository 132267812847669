import React from 'react';

// components
import { Text } from 'components';

// types
import { ProjectType } from 'types/types';

// constants
import { DOWNLOAD_LINKS, PROJECT_ICONS } from 'constants/projects';

// img
import appStoreIco from './img/appStoreIco.webp';
import playMarketIco from './img/playMarketIco.webp';

// styles
import styles from './DownloadBlock.module.css';

function DownloadBlock({ currentProject }: { currentProject: ProjectType }) {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                <img
                    className={styles.icon}
                    src={PROJECT_ICONS[currentProject]}
                    alt={currentProject}
                />
                <Text text="Download app" type="h2" />
            </div>

            <div className={styles.linksWrapper}>
                <a target="_blank" rel="noreferrer noopener" href={DOWNLOAD_LINKS[currentProject].APP_STORE}>
                    <img src={appStoreIco} alt="appStore" />
                </a>
                <a target="_blank" rel="noreferrer noopener" href={DOWNLOAD_LINKS[currentProject].PLAY_MARKET}>
                    <img src={playMarketIco} alt="playMarket" />
                </a>
            </div>
        </div>
    );
}

export default DownloadBlock;
