import React from 'react';
import { Link } from 'react-router-dom';

// img
import backArrow from './img/iconBack.svg';

// components
import { Text } from 'components';

// styles
import styles from './BackArrow.module.css';

const BackArrow = () => {
    return (
        <Link to="/" className={styles.container}>
            <img alt="back" src={backArrow} />
            <Text uppercase type="subtitle" text="back" />
        </Link>
    );
};

export default BackArrow;
