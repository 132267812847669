import React from 'react';

// constants
import { POLICY_LINKS } from 'constants/projects';

// types
import { ProjectType } from 'types/types';

// components
import { Text } from 'components';

// styles
import styles from './PolicyFooter.module.css';

function PolicyFooter({ currentProject }: { currentProject: ProjectType }) {
    const LINKS = [
        {
            text: 'Refund Policy',
            href: POLICY_LINKS[currentProject].REFUND_POLICY,
        },
        {
            text: 'Terms of Use',
            href: POLICY_LINKS[currentProject].TERM_OF_USE,
        },
        {
            text: 'Privacy Policy',
            href: POLICY_LINKS[currentProject].PRIVACY_POLICY,
        },
    ];

    return (
        <div className={styles.container}>
            {LINKS.map(({ text, href }) => (
                <a key={href} target="_blank" rel="noreferrer noopener" href={href}>
                    <Text text={text} type="caption" />
                </a>
            ))}
        </div>
    );
}

export default PolicyFooter;
