import FC from 'assets/img/projectIcons/FC.svg';
import MB from 'assets/img/projectIcons/MB.svg';
import OM from 'assets/img/projectIcons/OM.svg';
import FE from 'assets/img/projectIcons/FE.svg';
import YG from 'assets/img/projectIcons/YG.svg';
import WF from 'assets/img/projectIcons/WF.svg';

import fc1 from 'assets/slider/fc1.webp';
import fc2 from 'assets/slider/fc2.webp';
import fc3 from 'assets/slider/fc3.webp';
import mb1 from 'assets/slider/mb1.webp';
import mb2 from 'assets/slider/mb2.webp';
import mb3 from 'assets/slider/mb3.webp';
import fe1 from 'assets/slider/fe1.webp';
import fe2 from 'assets/slider/fe2.webp';
import fe3 from 'assets/slider/fe3.webp';
import om1 from 'assets/slider/om1.webp';
import om2 from 'assets/slider/om2.webp';
import om3 from 'assets/slider/om3.webp';
import yg1 from 'assets/slider/yg1.webp';
import yg2 from 'assets/slider/yg2.webp';
import yg3 from 'assets/slider/yg3.webp';
import wf1 from 'assets/slider/wf1.webp';
import wf2 from 'assets/slider/wf2.webp';
import wf3 from 'assets/slider/wf3.webp';

export const PROJECT_LIST = ['WALK_FIT', 'MUSCLE_BOOSTER', 'FIT_COACH', 'YOGA_GO', 'FAST_EASY', 'OMO'] as const;

export const PROJECT_NAMES = {
    WALK_FIT: 'WalkFit',
    MUSCLE_BOOSTER: 'Muscle Booster',
    FIT_COACH: 'FitCoach',
    YOGA_GO: 'Yoga-Go',
    FAST_EASY: 'FastEasy',
    OMO: 'Omo',
};

export const PROJECT_TITLES = {
    MUSCLE_BOOSTER: 'Home & Gym Workout Planner',
    FIT_COACH: 'Weight Loss: At-Home Workouts',
    YOGA_GO: 'Daily workouts for beginners',
    FAST_EASY: 'Weight Tracker & Meal Timer',
    OMO: 'Fasting, Calorie tracker, Diet',
    WALK_FIT: 'Step counter for weight loss'
};

export const DESCRIPTIONS = {
    MUSCLE_BOOSTER: {
        FIRST: 'When you work out at home or hit the gym, Muscle Booster’s smart training algorithm will guide you through the sets, reps, rep ranges and rest between sets, so that you can achieve your training goals.',
        SECOND: 'Videos with working muscles highlights, audio guides, smart devices support, exercise instructions, and many other features to enjoy the workout process.',
    },
    FIT_COACH: {
        FIRST: 'There is cardio, strength, stretching, yoga, pilates, HIIT, boxing, 7 minutes, recovery, morning, and more.',
        SECOND: 'Training with FitCoach is the same as training with a personal coach. There are comprehensive detailed workouts with high-quality video guides as well as step-by-step audio instructions and tips on how to perform the exercises.',
    },
    YOGA_GO: {
        FIRST: 'Yoga-Go aims at providing users with personalized yoga and pilates routines to build a toned and fit body, maintain balanced emotions, and harmonize their life.',
        SECOND: 'Yoga-Go is a source of simple home-based yoga and pilates workouts that will not take too much of your time. You can start a workout wherever you are at that moment. Yoga-Go workouts only take between 7-30 minutes, and you can burn up to 200 calories per session.',
    },
    FAST_EASY: {
        FIRST: 'Want to lose weight, get fit, and start mindful eating? Our fasting tracker is here to help, with daily fasting plans and diets, health tips, and weight-loss motivation!',
        SECOND: 'Intermittent fasting involves alternating from controlled periods of eating to avoiding food. This way, your glycogen levels deplete and your body enters ketosis — also known as the body’s “fat-burning” mode.',
    },
    OMO: {
        FIRST: 'А weight loss app that seeks to streamline the calorie counting process, making it easier than ever to log your meals, track workouts, and see results.',
        SECOND: 'If you are looking for an easy-to-use weight loss app that combines all the essential aspects of weight loss, including diet, fitness, and motivation, look no further than the Omo app.',
    },
    WALK_FIT: {
        FIRST: 'WalkFit is like your personal walking coach. It offers varied walking programs, creates a dashboard with your progress and insights, and provides diverse fitness workouts. WalkFit helps people with different fitness levels to achieve their goals with ease and fun!',
        SECOND: 'The app offers structured walking programs varying in intensity and duration. This includes indoor walking, interval walking, hill walk workouts, and treadmill workouts. Provides the ability to track your daily activities and encourages healthy habits. It can be synced with devices like Apple Watch and Fitbit, providing you with understandable stats, and allowing you to control the app on your wrist. With this app, evaluating your walking sessions becomes simple with accessible stats such as pace, distance, and calories burned.'
    },
};

export const PROJECT_ICONS = {
    MUSCLE_BOOSTER: MB,
    FIT_COACH: FC,
    YOGA_GO: YG,
    FAST_EASY: FE,
    OMO: OM,
    WALK_FIT: WF,
};

export const SLIDER_LIST = {
    MUSCLE_BOOSTER: [mb1, mb2, mb3],
    FIT_COACH: [fc1, fc2, fc3],
    YOGA_GO: [yg1, yg2, yg3],
    FAST_EASY: [fe1, fe2, fe3],
    OMO: [om1, om2, om3],
    WALK_FIT: [wf1, wf2, wf3],
};

export const DOWNLOAD_LINKS = {
    MUSCLE_BOOSTER: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=musclebooster.workout.home.gym.abs.loseweight',
        APP_STORE: 'https://apps.apple.com/app/workouts-by-muscle-booster/id1446447749',
    },
    FIT_COACH: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=net.workout.lose.weight.fitness.fit.coach',
        APP_STORE: 'https://apps.apple.com/app/fitness-coach-diet-fitcoach/id1438092960',
    },
    YOGA_GO: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=net.beginners.weight.loss.workout.women.yoga.go',
        APP_STORE: 'https://apps.apple.com/app/yoga-go-yoga-for-weight-loss/id1361619409',
    },
    FAST_EASY: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=fasteasy.dailyburn.fastingtracker',
        APP_STORE: 'https://apps.apple.com/app/fasteasy-intermittent-fasting/id1553036888',
    },
    OMO: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=calorie.counter.lose.weight.track',
        APP_STORE: 'https://apps.apple.com/app/omo-fitness-weight-loss/id1515595647',
    },
    WALK_FIT: {
        PLAY_MARKET: 'https://play.google.com/store/apps/details?id=com.walkfit.weightloss.steptracker.pedometer&hl=en&gl=US&pli=1',
        APP_STORE: 'https://apps.apple.com/us/app/walking-weight-loss-walkfit/id1457956232',
    },
};

export const POLICY_LINKS = {
    MUSCLE_BOOSTER: {
        REFUND_POLICY: 'https://muscle-booster.io/policy/refund-policy.html',
        TERM_OF_USE: 'https://muscle-booster.io/policy/terms-of-use.html',
        PRIVACY_POLICY: 'https://muscle-booster.io/policy/privacy-policy.html',
    },
    FIT_COACH: {
        REFUND_POLICY: 'https://fit-coach.io/policy/refund-policy.html',
        TERM_OF_USE: 'https://fit-coach.io/policy/terms-of-use.html',
        PRIVACY_POLICY: 'https://fit-coach.io/policy/privacy-policy.html',
    },
    OMO: {
        REFUND_POLICY: 'https://omo-app.io/policy/refund-policy.html',
        TERM_OF_USE: 'https://omo-app.io/policy/terms-of-use.html',
        PRIVACY_POLICY: 'https://omo-app.io/policy/privacy-policy.html',
    },
    FAST_EASY: {
        REFUND_POLICY: 'https://fasteasy.io/policy/refund-policy.html',
        TERM_OF_USE: 'https://fasteasy.io/policy/terms-of-use.html',
        PRIVACY_POLICY: 'https://fasteasy.io/policy/privacy-policy.html',
    },
    YOGA_GO: {
        REFUND_POLICY: 'https://yoga-go.io/policy/refund-policy.html',
        TERM_OF_USE: 'https://yoga-go.io/policy/terms-of-use.html',
        PRIVACY_POLICY: 'https://yoga-go.io/policy/privacy-policy.html',
    },
    WALK_FIT: {
        PRIVACY_POLICY: 'https://legal.walkfit.pro/page/privacy-policy?language=en',
        TERM_OF_USE: 'https://legal.walkfit.pro/page/terms-of-use?language=en',
        REFUND_POLICY: 'https://legal.walkfit.pro/page/refund-policy?language=en'
    },
};
