import React from 'react';

// img
import Arrow from './img/arrow.svg';

// styles
import styles from './ProductCard.module.css';

// components
import { Text } from 'components';

type ProductCardPropsType = {
    icon: string;
    title: string;
    subtitle?: string;
    onClick: () => void;
};

function ProductCard({ title, subtitle, icon, onClick }: ProductCardPropsType) {
    return (
        <div onClick={onClick} className={styles.container}>
            <img src={icon} className={styles.productIcon} alt={title} />
            <div>
                <Text text={title} type="body2" mb={4} />
                {subtitle && <Text text={subtitle} type="caption" />}
            </div>
            <img src={Arrow} alt="alt" className={styles.arrowIcon} />
        </div>
    );
}

export default ProductCard;
