import React from 'react';

// components
import { NavItem } from 'components';

// styles
import styles from './Navbar.module.css';

// types
import { NavBarItemsType } from 'types/types';

function Navbar({ items }: { items: NavBarItemsType[] }) {

    return (
        <div className={styles.container}>
            {items.map((product, index) => (
                <NavItem
                    key={index}
                    path={`/products/${index}`}
                    title={product.title}
                    icon={product.icon}
                    active={product.active}
                />
            ))}
        </div>
    );
}

export default Navbar;
