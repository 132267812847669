import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

// components
import { Text } from 'components';

// styles
import styles from './FieldComponent.module.css';

type FieldComponentPropsType = {
    label?: string;
    name: string;
    initValue?: string;
    placeholder?: string;
    type?: string;
    disabled?: boolean;
    required?: boolean;
    onChange?: (value: string | number) => void;
    mb?: number;
    isTextArea?: boolean;
};

const cx = classNames.bind(styles);

function FieldComponent({
    label,
    name,
    initValue,
    placeholder,
    type = 'text',
    onChange,
    mb,
    required,
    disabled,
    isTextArea,
}: FieldComponentPropsType) {
    const [value, setValue] = useState(initValue);
    const [isTouched, setIsTouched] = useState(false);

    useEffect(() => {
        initValue && setValue(initValue);
    }, [initValue]);

    const blurHandler = () => {
        value && !isTouched && setIsTouched(true);
    };

    const onChangeHandler = (e: any) => {
        setValue(e.target.value);
        onChange?.(e?.target?.value);
    };

    const fieldSettings = {
        type: type,
        placeholder: placeholder || name,
        name: name,
        onBlur: blurHandler,
        value: value,
        onChange: onChangeHandler,
        className: cx('input', { disabled }),
        required,
    };

    const inputWithSettings = () =>
        isTextArea ? <textarea {...fieldSettings} /> : <input {...fieldSettings} />;

    const SelectInputTemplate = () => {
        return (
            <div>
                <input
                    checked={Boolean(value)}
                    required
                    id={name}
                    name={name}
                    value={value}
                    type="checkbox"
                    className={styles.mappedInput}
                />
                <Text
                    text={value || placeholder || name}
                    type="body3"
                    color={value ? 'text-default' : 'text-subdued'}
                />
            </div>
        );
    };

    return (
        <label style={{ marginBottom: `${mb}px` }} className={cx('container', { disabled })}>
            <Text
                type="caption"
                color={value ? 'text-subdued' : 'text-default'}
                text={label || name}
                mb={2}
            />
            {type === 'select' ? <SelectInputTemplate /> : inputWithSettings()}
        </label>
    );
}

export default FieldComponent;
