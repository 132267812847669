import React from 'react';

// styles
import styles from './Loader.module.css';

const Loader = () => {

    return <div className={styles.loader} />;
};

export default Loader;
