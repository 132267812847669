import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// components
import {DownloadBlock, Text, Navbar, Slider, PolicyFooter, BackArrow, FeedBackForm} from 'components';

// styles
import styles from './Product.module.css';

// constants
import {
    PROJECT_ICONS,
    PROJECT_LIST,
    PROJECT_NAMES,
    DESCRIPTIONS,
    PROJECT_TITLES,
} from 'constants/projects';

// types
import { ProjectType } from 'types/types';

function Product() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { id } = useParams();

    const currentProject: ProjectType = id ? PROJECT_LIST[Number(id)] : PROJECT_LIST[0];

    return (
        <>
            <BackArrow />
            <div className={styles.container}>
                <div className="mediaDesktop">
                    <Text text="Our products" type="title" />
                </div>
                <div className={styles.contentWrapper}>
                    <Navbar
                        items={PROJECT_LIST.map((item, index) => ({
                            icon: PROJECT_ICONS[item],
                            title: PROJECT_NAMES[item],
                            active: PROJECT_LIST[index] === PROJECT_LIST[Number(id)],
                        }))}
                    />
                    <div className={styles.productWrapper}>
                        <div className={styles.productHeading}>
                            <img
                                className={styles.icon}
                                src={PROJECT_ICONS[currentProject]}
                                alt={currentProject}
                            />
                            <div>
                                <Text type="h2" mb={4} text={PROJECT_NAMES[currentProject]} />
                                <Text type="caption" text={PROJECT_TITLES[currentProject]} />
                            </div>
                        </div>
                        <Text type="body1" mb={16} text={DESCRIPTIONS[currentProject].FIRST} />
                        <Text type="body3" mb={40} text={DESCRIPTIONS[currentProject].SECOND} />
                        <Slider currentProject={currentProject} />
                        <div className={styles.footer}>
                            <DownloadBlock currentProject={currentProject} />
                            <PolicyFooter currentProject={currentProject} />
                        </div>
                    </div>
                </div>
                <FeedBackForm />
            </div>
        </>
    );
}

export default Product;
