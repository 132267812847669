import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import classNames from 'classnames/bind';

// styles
import styles from './FeedBackForm.module.css';

// img
import supportIcon from './img/supportIcon.svg';
import closeIco from './img/closeIcon.svg';

// components
import { Text, FieldComponent, DropDownInput } from 'components';

// config
import config from 'config.js';

// types
import { PRIVACY_POLICY } from '../../constants';
import { PROJECT_NAMES } from '../../constants/projects';
import Loader from '../Loader';
import NotificationMessage from '../NotificationMessage';
import { NotificationStatusType } from '../NotificationMessage/NotificationMessage';

const cx = classNames.bind(styles);

const FeedBackForm = ({ home }: { home?: boolean }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isListOpen, setIsListOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<string | null>(null);
    const [notificationStatus, setNotificationStatus] = useState<NotificationStatusType | null>(
        null,
    );

    const formRef = useRef<HTMLFormElement | null>(null);

    useEffect(() => {
        const form = document.getElementById('form');

        form?.addEventListener('submit', sendEmail);

        return () => form?.removeEventListener('submit', sendEmail);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setNotificationMessage(null);
            setNotificationStatus(null);
        }, 2000);

        return () => clearTimeout(timer);
    }, [notificationStatus]);

    const sendEmail = (e: Event) => {
        e.preventDefault();
        setIsLoading(true);

        emailjs
            .sendForm(
                config.EMAILJS_SERVICE_ID,
                config.EMAILJS_TEMPLATE_ID,
                e.target as HTMLFormElement,
                config.EMAILJS_PUBLIC_KEY,
            )
            .then(res => {
                console.log(res);

                setNotificationMessage('Feedback was sent');
                setNotificationStatus('ok');
            })
            .catch(error => {
                console.log(error);

                setNotificationMessage('Feedback was not sent');
                setNotificationStatus('critical');
            })
            .finally(() => {
                formRef.current?.reset();
                setTimeout(() => {
                    setIsLoading(false);
                    setIsOpen(false);
                }, 150);
            });
    };

    return (
        <>
            <div
                className={cx('formWrapper', { isOpen })}
                onClick={() => isListOpen && setIsListOpen(false)}
            >
                <div className={styles.shadow} />
                <div className={styles.formContent}>
                    <div className={styles.textBlockWrapper}>
                        <button onClick={() => setIsOpen(false)} className={styles.closeButton}>
                            <img src={closeIco} alt="close" />
                            <Text uppercase type="subtitle" text="close" />
                        </button>
                        <Text text="Hi!" type="h1-tablet" />
                        <Text
                            text="We would like to hear your feedback and we can answer any of your questions."
                            type="body2"
                            color="text-subdued"
                        />
                    </div>
                    <form id="form" className={styles.inputsWrapper}>
                        <FieldComponent
                            mb={18}
                            name="email"
                            label="Your email"
                            placeholder="Email"
                            required
                        />
                        <div onClick={() => setIsListOpen(true)}>
                            <DropDownInput
                                mb={18}
                                name="product"
                                placeholder="Product"
                                label="What product did you purchase?"
                                valuesList={Object.values(PROJECT_NAMES)}
                                required
                                isOpen={isListOpen}
                            />
                        </div>
                        <FieldComponent
                            name="message"
                            label="Your message"
                            placeholder="Message"
                            isTextArea
                            mb={18}
                        />
                        <div className={styles.buttonPart}>
                            <Text type="caption" color="text-subdued" mb={16}>
                                You can learn more about how we handle your personal data and your
                                rights by reviewing{' '}
                                <a target="_blank" rel="noreferrer"  href={`../../${PRIVACY_POLICY}`}>
                                    Privacy notice.
                                </a>
                            </Text>
                            <label className={cx('submit', { isLoading })}>
                                <input type="submit" value="send" className={styles.submitInput} />
                                {isLoading ? <Loader /> : 'send'}
                            </label>
                        </div>
                    </form>
                </div>
            </div>
            <button className={cx('button', { home })} onClick={() => setIsOpen(true)}>
                <img className={styles.supportIcon} src={supportIcon} alt="support" />
            </button>
            {Boolean(notificationStatus && notificationMessage) && (
                <NotificationMessage
                    message={notificationMessage || ''}
                    status={notificationStatus}
                />
            )}
        </>
    );
};

export default FeedBackForm;
