import React from 'react';

// components
import { Logo } from 'components';

import styles from './Footer.module.css';

function Footer() {
    return (
        <div className={styles.container}>
            <Logo mb={8} />
        </div>
    );
}

export default Footer;
