import React from 'react';

// components
import { Text } from 'components';

// styles
import styles from './NotificationMessage.module.css';
import classNames from 'classnames/bind';

export type NotificationStatusType = 'ok' | 'warning' | 'critical' | null;

const cx = classNames.bind(styles);
const NotificationMessage = ({
    message,
    status,
}: {
    message: string;
    status: NotificationStatusType;
}) => {
    return (
        <div className={cx('container', { ...(status && { [status]: status }) })}>
            <Text text={message} />
        </div>
    );
};

export default NotificationMessage;
