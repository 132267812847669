import React from 'react';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

// components
import { Text } from 'components';

// styles
import styles from './NavItem.module.css';

// types
import { NavBarItemsType } from 'types/types';

const cx = classNames.bind(styles);

type NavItemType = NavBarItemsType & { path: string };

const NavItem = ({ title, icon, active, path }: NavItemType) => {
    const linkClasses = cx({
        container: true,
        active,
    });

    return (
        <Link to={path} className={linkClasses}>
            <img className={styles.icon} src={icon} alt={title} />
            <div className="mediaDesktop">
                <Text text={title} type="label" color={active ? 'text-default' : 'text-subdued'} />
            </div>
        </Link>
    );
};

export default NavItem;
