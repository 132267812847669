import React, { useState } from 'react';
import classNames from 'classnames/bind';

//
import { FieldComponent, Text } from '../index';
import arrow from '../FeedBackForm/img/arrow.svg';
import styles from '../FeedBackForm/FeedBackForm.module.css';

const cx = classNames.bind(styles);

type DropDownInputPropsType = {
    valuesList: string[];
    name: string;
    placeholder?: string;
    label?: string;
    mb?: number;
    onChange?: (value: string | number) => void;
    required?: boolean;
    isOpen?: boolean;
};

const DropDownInput = ({
    valuesList,
    name,
    placeholder,
    label,
    mb,
    onChange,
    required,
    isOpen = false,
}: DropDownInputPropsType) => {
    const [selectedProject, setSelectedProject] = useState<undefined | string>(undefined);

    return (
        <div className={cx('projectFieldWrapper', 'dropDownInput')}>
            <FieldComponent
                disabled
                name={name}
                required={required}
                label={label || name}
                placeholder={placeholder || name}
                mb={mb}
                type='select'
                onChange={onChange}
                initValue={selectedProject}
            />
            <img className={styles.arrow} src={arrow} alt="arrow" />
            <div className={cx('projectList', { show: isOpen })}>
                <Text text="What product did you purchase?" mb={14} type="caption" />
                {valuesList.map((item, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedProject(item)}
                        className={cx('projectNameWrap', {
                            selected: item === selectedProject,
                        })}
                    >
                        <Text type="body3" text={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DropDownInput;
